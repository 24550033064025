import { cloneDeep } from 'lodash'

/**
 * 驼峰转下划线
 *
 * @param {string} str
 */
export function hump2UnderLine(str: string) {
  return str.replace(/([A-Z])/g, '_$1').toLowerCase()
}

export function isUnique(newTag: string, tags: Array<string>) {
  console.log(tags.filter(tag => tag === newTag))
  for (const iter of tags) {
    if (iter === newTag) return false
  }
  return true
  // return tags.filter(tag => tag === newTag).length === 0
}

export function errorHandler(err: any) {
  if (typeof err === 'string') return err
  else if (err.message) return err.message
  else return err.toString()
}

/**
 * 对象数组头部插入新数据
 *
 * @param data
 * @param list
 * @param pagination
 */
export function addItemObjectArray<T>(
  data: T,
  oList: T[],
  pagination: number[]
): T[] {
  let list = cloneDeep(oList)

  // 如果当前页数据已满，防止自动换页，先删去除末尾项
  if (pagination.indexOf(list.length) >= 0) {
    list.pop()
  }

  list.unshift(data)

  return list
}

/**
 * 更新对象数组内部指定项数据
 *
 * @param data
 * @param list
 */
export function updateObjectArrayItem<
  T extends { id: number | string },
  S extends { id: number | string }
>(data: S, list: T[]): T[] {
  let nlist = cloneDeep(list)

  nlist.every(item => {
    if (item.id === data.id) {
      Object.assign(item, data)
      return false
    } else {
      return true
    }
  })

  return nlist
}

/**
 * 删除对象数组中指定项
 *
 * @param data
 * @param oList
 */
export function deleteItemInObjectArray<T extends { id: number | string }>(
  data: { id: number | string },
  oList: T[]
): T[] {
  let list = cloneDeep(oList)

  list.forEach((item, index) => {
    if (item.id === data.id) {
      list.splice(index, 1)
    }
  })

  return list
}

export function downloadImage(
  url: string,
  name: string = '下载',
  env = '/bjcx'
) {
  if (url.includes('https://mp.weixin.qq.com')) {
    url = url.replace('https://mp.weixin.qq.com/cgi-bin/', env + '/qrcode/')
  }
  const image = document.createElement('img')
  // 解决跨域 Canvas 污染问题
  image.setAttribute('crossOrigin', 'anonymous')
  image.onload = function () {
    const canvas = document.createElement('canvas')
    canvas.width = image.width
    canvas.height = image.height

    const context = canvas.getContext('2d')
    context!.drawImage(image, 0, 0, image.width, image.height)
    const url = canvas.toDataURL('image/png')

    // 生成一个a元素
    const a = document.createElement('a')
    // 创建一个单击事件
    const event = new MouseEvent('click')

    // 将a的download属性设置为我们想要下载的图片名称，若name不存在则使用‘下载图片名称’作为默认名称
    a.download = name
    // 将生成的URL设置为a.href属性
    a.href = url
    // 触发a的单击事件
    a.dispatchEvent(event)
  }

  image.src = url
}

export const transferHump = (originObj: Object) => {
  const result = {}

  const reg = /_{1,2}\w/g // 这里正则的格式取决于自己
  const keys = Object.keys(originObj)
  for (let key of keys) {
    const value = originObj[key]
    key = key.replace(reg, match =>
      match.replace(/_/g, '').toLocaleUpperCase()
    )
    result[key] = value
  }

  return result
}

export const toQueryString = (obj: Object) => {
  var str: string[] = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p) && obj[p]) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return '?' + str.join("&");
}