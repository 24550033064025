export const SET_REGION_LIST = 'SET_REGION_LIST'
export type SET_REGION_LIST = typeof SET_REGION_LIST

export const SET_TAXI_AREA_MODEL_LIST = 'SET_TAXI_AREA_MODEL_LIST'
export type SET_TAXI_AREA_MODEL_LIST = typeof SET_TAXI_AREA_MODEL_LIST

export const SET_TAXI_AREA_MODEL_PARAMS = 'SET_TAXI_AREA_MODEL_PARAMS'
export type SET_TAXI_AREA_MODEL_PARAMS = typeof SET_TAXI_AREA_MODEL_PARAMS

export const SET_CAR_CATE = 'SET_CAR_CATE'
export type SET_CAR_CATE = typeof SET_CAR_CATE
