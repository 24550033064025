import * as constants from './constants'

import { AllNoticeActionTypes } from './actions'

import * as APIParams from 'src/types/notice/request'
import * as APIResponses from 'src/types/notice/response'

import { cloneDeep } from 'lodash'

export interface NoticeState {
  list: APIResponses.NoticeList
}

const INITAL_STATE: NoticeState = {
  list: {
    rows: [],
    total: 0
  }
}

const noticeReducer = (
  state: NoticeState = INITAL_STATE,
  action: AllNoticeActionTypes
) => {
  let list: APIResponses.NoticeItem[] = []

  switch (action.type) {
    case constants.SET_NOTICE_LIST:
      return {
        ...state,
        list: action.data
      }
    case constants.ADD_NOTICE:
      list = _add(action.data, state.list.rows)
      return {
        ...state,
        list: {
          rows: list,
          total: ++state.list.total
        }
      }
    case constants.DELETE_NOTICE:
      list = _delete(action.data.id, state.list.rows)
      return {
        ...state,
        list: {
          rows: list,
          total: --state.list.total
        }
      }
    case constants.UPDATE_NOTICE:
      list = _update(action.data, state.list.rows)
      return {
        ...state,
        list: {
          rows: list,
          total: state.list.total
        }
      }
    default:
      return state
  }
}

const _add = (
  data: APIResponses.NoticeItem,
  oList: APIResponses.NoticeItem[]
): APIResponses.NoticeItem[] => {
  let list = cloneDeep(oList)

  // 如果当前页数据已满，防止自动换页，先删去除末尾项
  if (list.length === 10 || list.length === 25 || list.length === 50) {
    list.pop()
  }

  list.unshift(data)

  return list
}

const _delete = (
  id: string,
  oList: APIResponses.NoticeItem[]
): APIResponses.NoticeItem[] => {
  let list = cloneDeep(oList)

  return list.filter(item => item.id !== id)
}

const _update = (
  data: APIParams.UpdateNotice,
  oList: APIResponses.NoticeItem[]
): APIResponses.NoticeItem[] => {
  let list = cloneDeep(oList)

  // 更新状态为发布时需要额外处理（仅能有一条发布的公告）
  console.log(data, data.released)
  if (data.released === true) {
    for (let i = 0; i < list.length; i++) {
      list[i].released = false
    }
  }

  list.every(item => {
    if (item.id === data.id) {
      Object.assign(item, data)
      return false
    } else {
      return true
    }
  })

  return list
}

export default noticeReducer
