import * as constants from './constants'
import { AllLineActionTypes } from './actions'
import * as APIRespones from 'src/types/line/respones'
import * as APIParams from 'src/types/line/request'
import { RegionPointAndPolygon } from 'src/types/regions'
// import { version } from 'moment'

export interface LineState {
  companyLines: APIRespones.CompanyLineList
  addScheduleCompanyLines: APIRespones.CompanyLineList
  batchList: APIRespones.ListBase<APIRespones.BatchTable>
  scheduleList: APIRespones.ListBase<APIRespones.SchedulingTable>
  orderList: APIRespones.ListBase<APIRespones.LineOrderDataSource>
  scheduleSearchFrom: APIParams.SchedulingNeeded
  staffList: APIRespones.PublicationData[]
  carList: APIRespones.PublicationData[]
  staffCompanys: APIRespones.PublicationData[]
  seatsInfoList: APIRespones.SeatsData[]
  ferryDriverList: APIRespones.FerryDriverData[]
  areaList: APIRespones.AreaList
  systemAreaList: APIRespones.AreaList
  infoWithPrice?: APIRespones.InfoWithPrice
  region?: RegionPointAndPolygon
  busLineInfo?: APIRespones.BusLineInfoData
}

const INITAL_STATE: LineState = {
  companyLines: [],
  scheduleList: { rows: [], total: 0 },
  orderList: { rows: [], total: 0 },
  batchList: { rows: [], total: 0 },
  scheduleSearchFrom: {
    page_num: 1,
    page_size: 10
  },
  staffList: [],
  carList: [],
  seatsInfoList: [],
  ferryDriverList: [],
  staffCompanys: [],
  addScheduleCompanyLines: [],
  areaList: [],
  systemAreaList: []
}

function lineReducer(
  state = INITAL_STATE,
  action: AllLineActionTypes
): LineState {
  switch (action.type) {
    case constants.SET_COMPANY_LINES:
      return {
        ...state,
        companyLines: action.data
      }
    case constants.SET_SCHEDULING_LINES:
      return { ...state, scheduleList: action.data }
    case constants.SET_CAR_LIST:
      return { ...state, carList: action.data }
    case constants.SET_SCHEDULING_FROM:
      return { ...state, scheduleSearchFrom: action.data }
    case constants.SET_STAFF_LIST:
      return { ...state, staffList: action.data }
    case constants.SET_COMPANY_LINES:
      return { ...state, carList: action.data }
    case constants.SET_LINE_ORDER_LIST:
      return { ...state, orderList: action.data }
    case constants.SET_SEATS_INFO_LIST:
      return { ...state, seatsInfoList: action.data }
    case constants.SET_FERRY_DRIVER_LIST:
      return { ...state, ferryDriverList: action.data }
    case constants.SET_STAFF_COMPANY_LIST:
      return { ...state, staffCompanys: action.data }
    case constants.SET_ADD_SCHEDULING_STAFF_COMPANY:
      return { ...state, addScheduleCompanyLines: action.data }
    case constants.SET_INFO_WITH_PRICE:
      return { ...state, infoWithPrice: action.data }
    case constants.SET_CHANGE_BATCHS:
      return { ...state, batchList: action.data }
    case constants.SET_POINT_CONTENT:
      return { ...state, region: action.data }
    case constants.SET_BUS_LINE_INFO:
      return { ...state, busLineInfo: action.data }
    case constants.SET_LINE_AREA:
      return { ...state, areaList: action.data }
    case constants.SET_SYSTEM_AREA:
      return {
        ...state,
        systemAreaList: action.data
      }
    default:
      return state
  }
}

export default lineReducer
