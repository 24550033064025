import * as constants from './constants'
import { AllCouponActionTypes } from './actions'
import * as APIParams from 'src/types/coupon/request'
import * as APIRespones from 'src/types/coupon/respones'
import { cloneDeep } from 'lodash'

export interface CouponState {
  list: APIRespones.CouponList
}

const INITAL_STATE: CouponState = {
  list: {
    rows: [],
    total: 0
  }
}

function couponReducer(state = INITAL_STATE, action: AllCouponActionTypes) {
  let newList: APIRespones.CouponItem[] = []
  switch (action.type) {
    case constants.SET_COUPON_LIST:
      return {
        ...state,
        list: action.data
      }
    case constants.ADD_COUPON:
      newList = _addCoupon(state.list.rows, action.data)
      return {
        ...state,
        list: {
          rows: newList,
          total: ++state.list.total
        }
      }
    case constants.UPDATE_COUPON:
      newList = _updateCoupon(
        state.list.rows,
        action.data.id,
        action.data.param
      )
      return {
        ...state,
        list: {
          rows: newList,
          total: state.list.total
        }
      }
    default:
      return state
  }
}

const _addCoupon = (
  couponList: APIRespones.CouponItem[],
  data: APIRespones.CouponItem
) => {
  let list = cloneDeep(couponList)

  // 如果当前页数据已满，防止自动换页，先删去除末尾项
  if (list.length === 10 || list.length === 25 || list.length === 50) {
    list.pop()
  }

  list.unshift(data)

  return list
}

const _updateCoupon = (
  couponList: APIRespones.CouponItem[],
  id: number,
  data: APIParams.UpdateCoupon
) => {
  let list = cloneDeep(couponList)

  list.forEach(item => {
    if (item.couponId === id) {
      Object.assign(item, data)
    }
  })

  return list
}

export default couponReducer
