import { Reducer } from 'redux'
import { AllStatisticalType } from './actions'
import {
  ListBase,
  SpecailDriver,
  StaffSpecailInfo,
  InterCitySpecialData
} from 'src/types/statistical/output'
import * as constants from './constants'
import {
  SpecailDriverParams,
  InterCitySpecialParams
} from 'src/types/statistical/input'
import {
  getCurrentMonthStart,
  getCurrentMonthEnd
} from 'src/utils/time/timeUtils'

const initialState: StatisticalState = {
  specailDriver: { rows: [], total: 0 },
  specailDriverParams: {
    page_size: 10,
    page_num: 1,
    range: 0,
    start_time: getCurrentMonthStart(),
    start_time_range: getCurrentMonthStart() + ' - ' + getCurrentMonthEnd()
  },
  interCityStaffData: { rows: [], total: 0 },
  interCityParams: {
    begin_date: getCurrentMonthStart('yyyy-MM-dd hh:mm:ss'),
    end_date: getCurrentMonthEnd('yyyy-MM-dd hh:mm:ss'),
    staff_name: '',
    company_id: '',
    bus_line_id: '',
    page_size: 10,
    page_num: 1
  }
}

export interface StatisticalState {
  specailDriver: ListBase<SpecailDriver>
  // specailDriverPageNum: number
  // specailDriverPageSize: number
  specailDriverParams: SpecailDriverParams
  interCityStaffData: ListBase<InterCitySpecialData>
  interCityParams: InterCitySpecialParams
  staffInfo?: StaffSpecailInfo
}

const reducer: Reducer<StatisticalState, AllStatisticalType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case constants.SET_SPECAIL_DRIVER:
      console.warn(action.data)
      return {
        ...state,
        specailDriver: action.data
      }
    case constants.SET_SPECAIL_DRIVER_PAGE:
      return {
        ...state,
        specailDriverParams: action.data
      }
    case constants.SET_STAFF_SPECAIL_INFO:
      return {
        ...state,
        staffInfo: action.data
      }
    case constants.SET_INTER_CITY_PARAMS:
      return {
        ...state,
        interCityParams: action.data
      }
    case constants.SET_INTER_CITY_SPECAIL_DATA:
      return { ...state, interCityStaffData: action.data }
    default:
      return state
  }
}

export default reducer
