import * as constants from './constants'
import { AllCarActionTypes } from './actions'
import * as APIParams from 'src/types/car/request'
import * as APIRespones from 'src/types/car/respones'
import { cloneDeep, omit, mapKeys } from 'lodash'
import { hump2UnderLine } from 'src/utils/tool'

export interface CarState {
  infoList: APIRespones.CarInfoItem[]
  categoryList: APIRespones.CarCategoryItem[]
  manageList: APIRespones.CarList
  charterCategoryList: APIRespones.CharterCategoryList
  carSafetyInfoList: APIRespones.CarSafetyInfoList
  carDetail: APIRespones.CarItemDetail
  carAva: string
  carAvaUpToken: string
  carCharterCategoryAva: string
  carCharterCategoryAvaUpToken: string
}

const INITAL_STATE: CarState = {
  infoList: [],
  categoryList: [],
  manageList: {
    rows: [],
    total: 0
  },
  charterCategoryList: {
    rows: [],
    total: 0
  },
  carSafetyInfoList: {
    rows: [],
    total: 0
  },
  carDetail: {
    brand: '',
    car_id: '',
    certificate: '',
    certify_date_b: '',
    check_state: '',
    commercial_type: 0,
    fee_print_id: '',
    fix_state: '',
    fuel_type: '',
    gps_brand: '',
    gps_install_date: '',
    gps_model: '',
    gpsimei: '',
    insur_com: '',
    insur_count: 0,
    insur_eff: '',
    insur_exp: '',
    insur_num: '',
    insur_type: '',
    next_fix_date: '',
    plate_color: '',
    register_date: '',
    trans_agercy: '',
    trans_area: '',
    trans_date_start: '',
    trans_date_stop: ''
  },
  carAva: '',
  carAvaUpToken: '',
  carCharterCategoryAva: '',
  carCharterCategoryAvaUpToken: ''
}

function carReducer(state = INITAL_STATE, action: AllCarActionTypes) {
  let newCarManageList: APIRespones.CarItem[] = []
  let newCharterCategoryList: APIRespones.CharterCategoryItem[] = []
  let newSafetyInfoList: APIRespones.CarSafetyInfoItem[] = []
  let newCarAva: string = ''

  switch (action.type) {
    case constants.SET_CAR_INFO_LIST:
      return {
        ...state,
        infoList: action.data
      }
    case constants.SET_CAR_CATEGORY_LIST:
      return {
        ...state,
        categoryList: action.data
      }
    case constants.SET_CAR_MANAGE_LIST:
      return {
        ...state,
        manageList: action.data
      }
    case constants.REMOVE_CAR:
      newCarManageList = _removeCarManage(
        state.manageList.rows,
        action.data.param
      )
      return {
        ...state,
        manageList: {
          rows: newCarManageList,
          total: state.manageList.total
        }
      }
    case constants.UPDATE_CAR:
      newCarManageList = _updateCarManage(
        state.manageList.rows,
        action.data.param
      )
      return {
        ...state,
        manageList: {
          rows: newCarManageList,
          total: state.manageList.total
        }
      }
    case constants.REMOVE_CAR_DRIVER:
      newCarManageList = _removeCarDriver(
        state.manageList.rows,
        action.data.param
      )
      return {
        ...state,
        manageList: {
          rows: newCarManageList,
          total: state.manageList.total
        }
      }
    case constants.UPDATE_CAR_CHARTER_PRICE:
      newCarManageList = _updateCarCharterAndRentPrice(
        state.manageList.rows,
        action.data.param
      )
      return {
        ...state,
        manageList: {
          rows: newCarManageList,
          total: state.manageList.total
        }
      }
    case constants.UPDATE_CAR_RENT_PRICE:
      newCarManageList = _updateCarCharterAndRentPrice(
        state.manageList.rows,
        action.data.param
      )
      return {
        ...state,
        manageList: {
          rows: newCarManageList,
          total: state.manageList.total
        }
      }
    case constants.SET_CAR_DETAIL:
      return {
        ...state,
        carDetail: action.data
      }
    case constants.SET_CAR_AVATAR:
      return {
        ...state,
        carAva: action.data
      }
    case constants.SET_CAR_AVATAR_UPTOKEN:
      return {
        ...state,
        carAvaUpToken: action.data
      }
    case constants.REMOVE_CAR_AVATAR:
      newCarAva = _removeCarAva(state.carAva, action.data.param)
      return {
        ...state,
        carAva: newCarAva
      }
    case constants.SET_CHARTER_CATEGORY_LIST:
      return {
        ...state,
        charterCategoryList: action.data
      }
    case constants.DISABLE_CHARTER_CATEGORY:
      newCharterCategoryList = _disableCharterCategory(
        state.charterCategoryList.rows,
        action.data.param
      )
      return {
        ...state,
        charterCategoryList: {
          rows: newCharterCategoryList,
          total: state.charterCategoryList.total
        }
      }
    case constants.UPDATE_CHARTER_CATEGORY:
      newCharterCategoryList = _updateCharterCategory(
        state.charterCategoryList.rows,
        action.data.param
      )
      return {
        ...state,
        charterCategoryList: {
          rows: newCharterCategoryList,
          total: state.charterCategoryList.total
        }
      }
    case constants.SET_CHARTER_CATEGORY_AVATAR:
      return {
        ...state,
        carCharterCategoryAva: action.data
      }
    case constants.SET_CHARTER_CATEGORY_AVATAR_UPTOKEN:
      return {
        ...state,
        carCharterCategoryAvaUpToken: action.data
      }
    case constants.SET_SATETY_INFO_LIST:
      return {
        ...state,
        carSafetyInfoList: action.data
      }
    case constants.REMOVE_CAR_SAFETY_INFO:
      newSafetyInfoList = _removeCarSafetyInfo(
        state.carSafetyInfoList.rows,
        action.data.param
      )
      return {
        ...state,
        carSafetyInfoList: {
          rows: newSafetyInfoList,
          total: state.carSafetyInfoList.total
        }
      }
    case constants.UPDATE_CAR_SAFETY_INFO:
      newSafetyInfoList = _updateCarSafetyInfo(
        state.carSafetyInfoList.rows,
        action.data.param
      )
      return {
        ...state,
        carSafetyInfoList: {
          rows: newSafetyInfoList,
          total: state.carSafetyInfoList.total
        }
      }
    default:
      return state
  }
}

const _removeCarAva = (orgAva: string, param: APIParams.RemoveCarAva) => {
  let ava = orgAva.split(',').filter(item => item !== param.avatar)
  console.log(orgAva.split(','), ava)
  return ava.join(',')
}

const _updateCarCharterAndRentPrice = (
  orgList: APIRespones.CarItem[],
  param: APIParams.UpdateCarCharterPrice | APIParams.UpdateCarRentPrice
) => {
  let list = cloneDeep(orgList)

  // 处理param对象，属性名驼峰转下划线（待后续接口统一数据格式）
  let data = mapKeys(param, (value, key) => hump2UnderLine(key))

  list.forEach(item => {
    if (item.id === data.car_id) {
      Object.assign(item, data)
    }
  })

  return list
}

const _removeCarManage = (
  orgList: APIRespones.CarItem[],
  param: APIParams.RemoveCar
) => {
  let list = cloneDeep(orgList)
  const carIds: string[] = param.ids.split(',')

  carIds.forEach(id => {
    const i = list.findIndex(item => item.id === id)
    if (i >= 0) {
      list.splice(i, 1)
    }
  })

  console.log('remove', carIds, list)

  return list
}

const _updateCarManage = (
  orgList: APIRespones.CarItem[],
  param: APIParams.UpdateCar
) => {
  let list = cloneDeep(orgList)

  // 处理param对象，去除多余属性，属性名驼峰转下划线（待后续接口统一数据格式）
  let data = mapKeys(omit(param, ['value']), (value, key) =>
    hump2UnderLine(key)
  )

  list.forEach(item => {
    if (item.id === data.id) {
      Object.assign(item, data)
    }
  })

  return list
}

const _removeCarDriver = (
  orgList: APIRespones.CarItem[],
  param: APIParams.RemoveCarDriver
) => {
  let list = cloneDeep(orgList)
  const carIds: string[] = param.ids.split(',')

  carIds.forEach(id => {
    const i = list.findIndex(item => item.id === id)
    if (i >= 0) {
      list[i].staff_name = ''
    }
  })

  console.log('remove driver', carIds, list)

  return list
}

const _disableCharterCategory = (
  orgList: APIRespones.CharterCategoryItem[],
  param: APIParams.DisableCharterCategory
) => {
  let list = cloneDeep(orgList)

  for (let i = 0; i < list.length; i++) {
    if (list[i].id === param.id) {
      Object.assign(list[i], param)
      break
    }
  }

  return list
}

const _updateCharterCategory = (
  orgList: APIRespones.CharterCategoryItem[],
  param: APIParams.UpdateCharterCategory
) => {
  let list = cloneDeep(orgList)

  // 处理param对象，去除多余属性，属性名驼峰转下划线（待后续接口统一数据格式）
  let data = mapKeys(omit(param, ['value']), (value, key) =>
    hump2UnderLine(key)
  )

  list.forEach(item => {
    if (item.id === data.id) {
      Object.assign(item, data)
    }
  })

  return list
}

const _removeCarSafetyInfo = (
  orgList: APIRespones.CarSafetyInfoItem[],
  param: APIParams.RemoveCarSafetyInfo
) => {
  let list = cloneDeep(orgList)
  const carIds: string[] = param.carIds.split(',')

  carIds.forEach(id => {
    const i = list.findIndex(item => item.car_id === id)
    if (i >= 0) {
      list.splice(i, 1)
    }
  })

  console.log('remove', carIds, list)

  return list
}

const _updateCarSafetyInfo = (
  orgList: APIRespones.CarSafetyInfoItem[],
  param: APIParams.UpdateCarSafetyInfo
) => {
  let list = cloneDeep(orgList)

  // 处理param对象，去除多余属性，属性名驼峰转下划线（待后续接口统一数据格式）
  let data = mapKeys(omit(param, ['value']), (value, key) =>
    hump2UnderLine(key)
  )

  list.forEach(item => {
    if (item.car_id === data.car_id) {
      Object.assign(item, data)
    }
  })

  return list
}

export default carReducer
