import * as constants from './constants'
import { AllAdPushActionTypes } from './actions'
import * as APIParams from 'src/types/adpush/request'
import * as APIRespones from 'src/types/adpush/respones'
import { cloneDeep } from 'lodash'

export interface AdPushState {
  list: APIRespones.AdPushList
  adpushCoverUpToken: string
  adpushCouponIdName: APIRespones.CouponIdName[]
}

const INITAL_STATE: AdPushState = {
  list: {
    rows: [],
    total: 0
  },
  adpushCoverUpToken: '',
  adpushCouponIdName: []
}

function adpushReducer(state = INITAL_STATE, action: AllAdPushActionTypes) {
  let newList: APIRespones.AdPushItem[] = []
  switch (action.type) {
    case constants.SET_ADPUSH_LIST:
      return {
        ...state,
        list: action.data
      }
    case constants.ADD_ADPUSH:
      newList = _addAdPush(state.list.rows, action.data)
      return {
        ...state,
        list: {
          rows: newList,
          total: ++state.list.total
        }
      }
    case constants.SET_ADPUSH_COVER_UPTOKEN:
      return {
        ...state,
        adpushCoverUpToken: action.data
      }
    case constants.SET_ADPUSH_COUPON_IDNAME:
      return {
        ...state,
        adpushCouponIdName: action.data
      }
    case constants.UPDATE_ADPUSH:
      newList = _updateAdPush(
        state.list.rows,
        action.data.id,
        action.data.param
      )
      return {
        ...state,
        list: {
          rows: newList,
          total: state.list.total
        }
      }
    case constants.DELETE_ADPUSH:
      console.log(action)
      newList = _deleteAdPush(
        state.list.rows,
        action.data.id
        // action.data.param
      )
      return {
        ...state,
        list: {
          rows: newList,
          total: --state.list.total
        }
      }
    default:
      return state
  }
}

const _addAdPush = (
  adpushList: APIRespones.AdPushItem[],
  data: APIRespones.AdPushItem
) => {
  console.log(adpushList, data)
  let list = cloneDeep(adpushList)
  // 如果当前页数据已满，防止自动换页，先删去除末尾项
  if (list.length === 10 || list.length === 25 || list.length === 50) {
    list.pop()
  }

  list.unshift(data)
  console.log(list)
  return list
}

const _updateAdPush = (
  adpushList: APIRespones.AdPushItem[],
  id: number,
  data: APIRespones.AdPushItem
) => {
  let list = cloneDeep(adpushList)

  list.forEach(item => {
    if (item.id === id) {
      Object.assign(item, data)
    }
  })
  return list
}

const _deleteAdPush = (
  adpushList: APIRespones.AdPushItem[],
  id: number
  // data: APIRespones.AdPushItem
) => {
  let list = cloneDeep(adpushList)
  list.forEach((item, index) => {
    if (item.id === id) {
      list.splice(index, 1)
    }
  })
  console.log(list)
  return list
}

export default adpushReducer
