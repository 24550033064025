export const SET_RESERVATION_LIST = 'SET_RESERVATION_LIST'
export type SET_RESERVATION_LIST = typeof SET_RESERVATION_LIST

export const SET_RESERVATION_SEARCH_PARAMS = 'SET_RESERVATION_SEARCH_PARAMS'
export type SET_RESERVATION_SEARCH_PARAMS = typeof SET_RESERVATION_SEARCH_PARAMS

export const UPDATE_RESERVATION = 'UPDATE_RESERVATION'
export type UPDATE_RESERVATION = typeof UPDATE_RESERVATION

export const SET_AGENT_LIST = 'SET_AGENT_LIST'
export type SET_AGENT_LIST = typeof SET_AGENT_LIST

export const SET_AGENT_SEARCH_PARAMS = 'SET_AGENT_SEARCH_PARAMS'
export type SET_AGENT_SEARCH_PARAMS = typeof SET_AGENT_SEARCH_PARAMS

export const SET_AGENT_LIST_SLIM = 'SET_AGENT_LIST_SLIM'
export type SET_AGENT_LIST_SLIM = typeof SET_AGENT_LIST_SLIM

export const SET_AGENT_ORDER_LIST = 'SET_AGENT_ORDER_LIST'
export type SET_AGENT_ORDER_LIST = typeof SET_AGENT_ORDER_LIST

export const ADD_AGENT = 'ADD_AGENT'
export type ADD_AGENT = typeof ADD_AGENT

export const UPDATE_AGENT = 'UPDATE_AGENT'
export type UPDATE_AGENT = typeof UPDATE_AGENT

export const SET_TIME_RANGE = 'SET_TIME_RANGE'
export type SET_TIME_RANGE = typeof SET_TIME_RANGE

export const SET_SETTING_LIST = 'SET_SETTING_LIST'
export type SET_SETTING_LIST = typeof SET_SETTING_LIST

export const ADD_SETTING = 'ADD_SETTING'
export type ADD_SETTING = typeof ADD_SETTING

export const UPDATE_SETTING = 'UPDATE_SETTING'
export type UPDATE_SETTING = typeof UPDATE_SETTING

export const DELETE_SETTING = 'DELETE_SETTING'
export type DELETE_SETTING = typeof DELETE_SETTING

export const SET_STATION_LIST_SLIM = 'SET_STATION_LIST_SLIM'
export type SET_STATION_LIST_SLIM = typeof SET_STATION_LIST_SLIM

export const SET_FEE_LIST = 'SET_FEE_LIST'
export type SET_FEE_LIST = typeof SET_FEE_LIST

export const SET_FEE_SEARCH_PARAMS = 'SET_FEE_SEARCH_PARAMS'
export type SET_FEE_SEARCH_PARAMS = typeof SET_FEE_SEARCH_PARAMS

export const ADD_FEE = 'ADD_FEE'
export type ADD_FEE = typeof ADD_FEE

export const UPDATE_FEE = 'UPDATE_FEE'
export type UPDATE_FEE = typeof UPDATE_FEE

export const DELETE_FEE = 'DELETE_FEE'
export type DELETE_FEE = typeof DELETE_FEE

export const SET_CAR_TYPES = 'SET_CAR_TYPES'
export type SET_CAR_TYPES = typeof SET_CAR_TYPES
