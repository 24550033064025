export const SET_CAR_MANAGE_LIST = 'SET_CAR_MANAGE_LIST'
export type SET_CAR_MANAGE_LIST = typeof SET_CAR_MANAGE_LIST

export const REMOVE_CAR = 'REMOVE_CAR'
export type REMOVE_CAR = typeof REMOVE_CAR

export const UPDATE_CAR = 'UPDATE_CAR'
export type UPDATE_CAR = typeof UPDATE_CAR

export const REMOVE_CAR_DRIVER = 'REMOVE_CAR_DRIVER'
export type REMOVE_CAR_DRIVER = typeof REMOVE_CAR_DRIVER

export const UPDATE_CAR_CHARTER_PRICE = 'UPDATE_CAR_CHARTER_PRICE'
export type UPDATE_CAR_CHARTER_PRICE = typeof UPDATE_CAR_CHARTER_PRICE

export const UPDATE_CAR_RENT_PRICE = 'UPDATE_CAR_RENT_PRICE'
export type UPDATE_CAR_RENT_PRICE = typeof UPDATE_CAR_RENT_PRICE

export const SET_CAR_DETAIL = 'SET_CAR_DETAIL'
export type SET_CAR_DETAIL = typeof SET_CAR_DETAIL

export const SET_CAR_AVATAR = 'SET_CAR_AVATAR'
export type SET_CAR_AVATAR = typeof SET_CAR_AVATAR

export const SET_CAR_AVATAR_UPTOKEN = 'SET_CAR_AVATAR_UPTOKEN'
export type SET_CAR_AVATAR_UPTOKEN = typeof SET_CAR_AVATAR_UPTOKEN

export const REMOVE_CAR_AVATAR = 'REMOVE_CAR_AVATAR'
export type REMOVE_CAR_AVATAR = typeof REMOVE_CAR_AVATAR

export const SET_CHARTER_CATEGORY_LIST = 'SET_CHARTER_CATEGORY_LIST'
export type SET_CHARTER_CATEGORY_LIST = typeof SET_CHARTER_CATEGORY_LIST

export const DISABLE_CHARTER_CATEGORY = 'DISABLE_CHARTER_CATEGORY'
export type DISABLE_CHARTER_CATEGORY = typeof DISABLE_CHARTER_CATEGORY

export const UPDATE_CHARTER_CATEGORY = 'UPDATE_CHARTER_CATEGORY'
export type UPDATE_CHARTER_CATEGORY = typeof UPDATE_CHARTER_CATEGORY

export const SET_CHARTER_CATEGORY_AVATAR = 'SET_CHARTER_CATEGORY_AVATAR'
export type SET_CHARTER_CATEGORY_AVATAR = typeof SET_CHARTER_CATEGORY_AVATAR

export const SET_CHARTER_CATEGORY_AVATAR_UPTOKEN =
  'SET_CHARTER_CATEGORY_AVATAR_UPTOKEN'
export type SET_CHARTER_CATEGORY_AVATAR_UPTOKEN = typeof SET_CHARTER_CATEGORY_AVATAR_UPTOKEN

export const SET_SATETY_INFO_LIST = 'SET_SATETY_INFO_LIST'
export type SET_SATETY_INFO_LIST = typeof SET_SATETY_INFO_LIST

export const REMOVE_CAR_SAFETY_INFO = 'REMOVE_CAR_SAFETY_INFO'
export type REMOVE_CAR_SAFETY_INFO = typeof REMOVE_CAR_SAFETY_INFO

export const UPDATE_CAR_SAFETY_INFO = 'UPDATE_CAR_SAFETY_INFO'
export type UPDATE_CAR_SAFETY_INFO = typeof UPDATE_CAR_SAFETY_INFO

export const SET_CAR_INFO_LIST = 'SET_CAR_INFO_LIST'
export type SET_CAR_INFO_LIST = typeof SET_CAR_INFO_LIST

export const SET_CAR_CATEGORY_LIST = 'SET_CAR_CATEGORY_LIST'
export type SET_CAR_CATEGORY_LIST = typeof SET_CAR_CATEGORY_LIST
