export const SET_SCHOOL_LIST = 'SET_SCHOOL_LIST'
export type SET_SCHOOL_LIST = typeof SET_SCHOOL_LIST

export const SET_SCHOOL_COURSE_LIST = 'SET_SCHOOL_COURSE_LIST'
export type SET_SCHOOL_COURSE_LIST = typeof SET_SCHOOL_COURSE_LIST

export const SET_SCHOOL_ORDER_LIST = 'SET_SCHOOL_ORDER_LIST'
export type SET_SCHOOL_ORDER_LIST = typeof SET_SCHOOL_ORDER_LIST

export const SET_SCHOOL_ORDER_PARAMS = 'SET_SCHOOL_ORDER_PARAMS'
export type SET_SCHOOL_ORDER_PARAMS = typeof SET_SCHOOL_ORDER_PARAMS

export const SET_SCHOOL_COURSE_PARAMS = 'SET_SCHOOL_COURSE_PARAMS'
export type SET_SCHOOL_COURSE_PARAMS = typeof SET_SCHOOL_COURSE_PARAMS

export const SET_SCHOOL_LIST_PARAMS = 'SET_SCHOOL_LIST_PARAMS'
export type SET_SCHOOL_LIST_PARAMS = typeof SET_SCHOOL_LIST_PARAMS
