import { Menu } from 'src/types/menu'
import { ROLE } from 'src/utils/authority'

const asideMenuConfig: Menu[] = [
  {
    name: '地区管理',
    path: '/region',
    icon: 'global',
    children: [
      { name: '地区管理', path: '/region/region' },
      {
        name: '网约车区域管理',
        path: '/region/area_model'
      }
    ]
  },
  {
    name: '线路排班',
    path: '/line',
    icon: 'line-chart',
    children: [
      {
        name: '线路管理',
        path: '/line/line'
      },
      { name: '排班管理', path: '/line/scheduling' }
    ]
  },
  {
    name: '车队管理',
    path: '/motorcade',
    icon: 'team',
    children: [
      {
        name: '运营企业',
        path: '/motorcade/enterprise'
      },
      {
        name: '员工管理',
        path: '/motorcade/employee'
      },
      {
        name: '车辆管理',
        path: '/motorcade/car'
      },
      {
        name: '包车车型设置',
        path: '/motorcade/charter'
      },
      {
        name: '车辆安全信息',
        path: '/motorcade/safety'
      }
    ]
  },
  {
    name: '系统管理',
    path: '/system',
    icon: 'control',
    children: [
      {
        name: 'APP配置',
        path: '/system/app'
      },
      {
        name: '小程序管理',
        path: '/system/mini'
      }
    ]
  },
  {
    id: 203,
    name: '营销中心',
    path: '/marketing',
    icon: 'dollar',
    children: [
      {
        name: '广告',
        path: '/marketing/advertising'
      },
      {
        name: '会员卡',
        path: '/marketing/vipcard'
      },
      {
        name: '通知推送',
        path: '/marketing/adpush'
      },
      {
        name: '通知分类管理',
        path: '/marketing/adsort'
      },
      {
        name: '优惠券',
        path: '/marketing/coupon'
      },
      {
        name: '系统公告',
        path: '/marketing/system_notice'
      },
      {
        name: '二维码推广',
        path: '/marketing/qr'
      }
    ]
  },
  {
    name: '统计',
    path: '/statistical',
    icon: 'line-chart',
    children: [
      {
        name: '快车业务统计',
        path: '/statistical/express'
      },
      {
        name: '城际司机统计',
        path: '/statistical/inter_city'
      }
    ]
  },
  {
    name: '服务管理',
    path: '/service',
    icon: 'appstore',
    children: [
      {
        name: '预约检车',
        path: '/service/car_inspection'
      },
      {
        name: '代检人员管理',
        path: '/service/agent_management'
      }
    ]
  },
  {
    name: '驾校报名',
    path: '/school',
    icon: 'appstore',
    children: [
      {
        name: '校区信息管理',
        path: '/school/list'
      },
      {
        name: '课程信息管理',
        path: '/school/course'
      },
      { name: '报名管理', path: '/school/order' }
    ]
  }
  // {
  //   name: '表格页',
  //   path: '/table',
  //   icon: 'table',
  //   children: [
  //     {
  //       name: 'basic',
  //       path: '/table/basic',
  //       authority: [ROLE.Admin]
  //     },
  //     {
  //       name: 'general',
  //       path: '/table/general',
  //       authority: [ROLE.User, ROLE.Admin]
  //     }
  //   ]
  // },
  // {
  //   name: '列表页',
  //   path: '/list',
  //   icon: 'copy',
  //   children: [
  //     {
  //       name: 'basic',
  //       path: '/list/basic'
  //     },
  //     {
  //       name: 'general',
  //       path: '/list/general'
  //     }
  //   ]
  // },
  // {
  //   name: 'profile',
  //   path: '/profile',
  //   icon: 'user',
  //   children: [
  //     {
  //       name: 'basic',
  //       path: '/profile/basic'
  //     },
  //     {
  //       name: 'terms',
  //       path: '/profile/general'
  //     }
  //   ]
  // },
  // {
  //   name: 'result',
  //   path: '/result',
  //   icon: 'hourglass',
  //   children: [
  //     {
  //       name: 'success',
  //       path: '/result/success'
  //     },
  //     {
  //       name: 'fail',
  //       path: '/result/fail'
  //     }
  //   ]
  // },
  // {
  //   name: 'account',
  //   path: '/account',
  //   icon: 'user',
  //   children: [
  //     {
  //       name: 'setting',
  //       path: '/account/setting'
  //     }
  //   ]
  // },
  // {
  //   name: 'exception',
  //   path: '/exception',
  //   icon: 'exception',
  //   children: [
  //     {
  //       name: '204',
  //       path: '/exception/204'
  //     },
  //     {
  //       name: '403',
  //       path: '/exception/403'
  //     },
  //     {
  //       name: '404',
  //       path: '/exception/404'
  //     },
  //     {
  //       name: '500',
  //       path: '/exception/500'
  //     }
  //   ]
  // }
]

export const baseMenu = [
  { name: '地区管理', path: '/region/region' },
  { name: '网约车区域管理', path: '/region/area_model' },
  { name: '线路管理', path: '/line/line' },
  { name: '排班管理', path: '/line/scheduling' },
  { name: '运营企业', path: '/motorcade/enterprise' },
  { name: '员工管理', path: '/motorcade/employee' },
  { name: '车辆管理', path: '/motorcade/car' },
  { name: '包车车型设置', path: '/motorcade/charter' },
  { name: '车辆安全信息', path: '/motorcade/safety' },
  { name: 'APP配置', path: '/system/app' },
  { name: '小程序管理', path: '/system/mini' },
  { name: '广告', path: '/marketing/advertising' },
  { name: '会员卡', path: '/marketing/vipcard' },
  { name: '通知推送', path: '/marketing/adpush' },
  { name: '通知分类管理', path: '/marketing/adsort' },
  { name: '优惠券', path: '/marketing/coupon' },
  { name: '系统公告', path: '/marketing/system_notice' },
  { name: '二维码推广', path: '/marketing/qr' },
  { name: '快车业务统计', path: '/statistical/express' },
  { name: '城际司机统计', path: '/statistical/inter_city' },
  { name: '预约检车', path: '/service/car_inspection' },
  { name: '代检人员管理', path: '/service/agent_management' },
  { name: '检车费用管理', path: '/service/fee_management' },
  { name: '校区信息管理', path: '/school/list' },
  { name: '课程信息管理', path: '/school/course' },
  { name: '报名管理', path: '/school/order' },
  { name: '定价管理', path: '/commission/driver_rule' },
  { name: '司机榜单', path: '/personnel/driver_rank' },
  { name: '司机审核', path: '/personnel/driver_review' },
  { name: '小队提点管理', path: '/personnel/team_commission' },
  { name: '小队管理', path: '/personnel/team_list' },
  { name: '提现管理', path: '/amount/withdrawal' },
  { name: '保险流水', path: '/amount/flowing' },
  { name: '活动管理', path: '/service/push' },
  { name: '优惠券管理', path: '/service/coupon' },
  { name: '关键字管理', path: '/service/keywords' },
  { name: '押金管理', path: '/service/deposit' },
  { name: '意见反馈', path: '/service/feedback' },
]

export { asideMenuConfig }
