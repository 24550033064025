import * as constants from './constants'

import { AllQrActionTypes } from './actions'

import * as APIResponses from 'src/types/qr/response'

// import { cloneDeep } from 'lodash'

export interface QrState {
  list: APIResponses.QRList
}

const INITAL_STATE: QrState = {
  list: {
    rows: [],
    total: 0
  }
}

const qrReducer = (state: QrState = INITAL_STATE, action: AllQrActionTypes) => {
  // let list: APIResponses.QRItem[] = []

  switch (action.type) {
    case constants.SET_QR_LIST:
      return {
        ...state,
        list: action.data
      }
    default:
      return state
  }
}

export default qrReducer
