import { SYSTEM_SET_MENU, SYSTEM_SET_INFO } from './constants'
import { InitInformation, MenuOrg } from 'src/types/system'
import { AllSystemAction } from './actions'
import { baseMenu } from 'src/router/menu/menuConfig'
import { Menu } from 'src/types/menu'

// The initial state of the login
const initialState = {
  menus: [] as Array<Menu>,
  initInformation: {
    menus: [] as MenuOrg[]
  } as InitInformation,
  isAdmin: false // 是否最高权限公司下属用户
}

export type SystemState = Readonly<typeof initialState>

function systemReducer(state = initialState, action: AllSystemAction) {
  switch (action.type) {
    case SYSTEM_SET_MENU:
      return Object.assign({}, state, {
        menu: action.data
      })
    case SYSTEM_SET_INFO:
      const menuPathMap = new Map<string, string>()
      for (const iter of baseMenu) {
        menuPathMap.set(iter.name, iter.path)
      }
      let menus: Menu[] = action.data.menus.map(menu => ({
        name: menu.name,
        children: menu.children.map(child => {
          let path = menuPathMap.get(child.name)!
          menuPathMap.delete(child.name)
          return {
            name: child.name,
            path
          }
        })
      }))

      console.log('menus', menus)
      // console.log(menuPathMap)

      return {
        ...state,
        menus: menus,
        initInformation: action.data,
        isAdmin: action.data.company_info.is_admin
      }
    default:
      return state
  }
}

export default systemReducer
