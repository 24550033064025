export function getDate(month: number, year?: number): number {
  const d = new Date()
  if (year) {
    d.setFullYear(year)
  }
  d.setMonth(month + 1)
  d.setDate(0)
  return d.getDate()
}

export function getWeeks(year: number, month: number, date: number) {
  const d = new Date(year, month, date)
  switch (d.getDay()) {
    case 0:
      return '日'
    case 1:
      return '一'
    case 2:
      return '二'
    case 3:
      return '三'
    case 4:
      return '四'
    case 5:
      return '五'
    case 6:
      return '六'
    default:
      return '未知'
  }
}

export function formatDate(date: Date, fmt = 'yyyy-MM-dd hh:mm:ss') {
  let o: any = {
    'M+': date.getMonth() + 1, // 月份
    'd+': date.getDate(), // 日
    'h+': date.getHours(), // 小时
    'm+': date.getMinutes(), // 分
    's+': date.getSeconds(), // 秒
    'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
    S: date.getMilliseconds() // 毫秒
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + '').substr(4 - RegExp.$1.length)
    )
  }
  for (let k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length)
      )
    }
  }
  return fmt
}

/**
 * 将yyyy-MM-dd hh:mm:ss格式的时间字符串转换为秒级unix时间戳
 *
 * @param {string} str 时间字符串
 */
export function string2Timestamp(str: string) {
  let [date, time] = str.split(' ')
  let [y, M, d] = date.split('-')
  let [h, m, s] = time.split(':')

  const DATE = new Date()
  DATE.setFullYear(Number(y), Number(M) - 1, Number(d))
  DATE.setHours(Number(h), Number(m), Number(s), 0)

  return Math.round(DATE.getTime() / 1000)
}

export function getCurrentMonthStart(format: string = 'yyyy-MM-dd'): string {
  let date = new Date()
  let y = date.getFullYear()
  let m = date.getMonth()
  let firstDay = new Date(y, m, 1)
  return formatDate(firstDay, format)
}

export function getCurrentMonthEnd(format: string = 'yyyy-MM-dd'): string {
  let date = new Date()
  let y = date.getFullYear()
  let m = date.getMonth()
  let lastDay = new Date(y, m + 1, 0)
  return formatDate(lastDay, format)
}
