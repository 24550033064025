export const SET_NOTICE_LIST = 'SET_NOTICE_LIST'
export type SET_NOTICE_LIST = typeof SET_NOTICE_LIST

export const ADD_NOTICE = 'ADD_NOTICE'
export type ADD_NOTICE = typeof ADD_NOTICE

export const DELETE_NOTICE = 'DELETE_NOTICE'
export type DELETE_NOTICE = typeof DELETE_NOTICE

export const UPDATE_NOTICE = 'UPDATE_NOTICE'
export type UPDATE_NOTICE = typeof UPDATE_NOTICE
