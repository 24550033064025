import { Reducer } from 'redux'
import { AllSchoolType } from './actions'
import * as APIResponse from 'src/types/driving_school_registration/response'
import * as APIRequest from 'src/types/driving_school_registration/request'

import * as constants from './constants'
import { BasePagination } from 'src/types'

const initialState: SchoolState = {
  schoolList: { rows: [], total: 0 },
  schoolCourseList: { rows: [], total: 0 },
  schoolListParams: { page_size: 10, page_num: 1 },
  schoolCourseParams: {
    page_num: 1,
    page_size: 10
  },
  schoolOrderParams: { page_num: 1, page_size: 10 },
  schoolOrderList: { rows: [], total: 0 }
}

export interface SchoolState {
  schoolListParams: BasePagination
  schoolList: APIResponse.SchoolList
  schoolCourseList: APIResponse.SchoolCourseList
  schoolCourseParams: Partial<APIRequest.CourseListParams>
  schoolOrderList: APIResponse.SchoolOrderList
  schoolOrderParams: Partial<APIRequest.SchoolOrderListParams>
}

const reducer: Reducer<SchoolState, AllSchoolType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case constants.SET_SCHOOL_LIST:
      return {
        ...state,
        schoolList: action.data
      }
    case constants.SET_SCHOOL_COURSE_LIST:
      return {
        ...state,
        schoolCourseList: action.data
      }
    case constants.SET_SCHOOL_COURSE_PARAMS:
      return {
        ...state,
        schoolCourseParams: action.data
      }
    case constants.SET_SCHOOL_ORDER_LIST:
      return {
        ...state,
        schoolOrderList: action.data
      }
    case constants.SET_SCHOOL_ORDER_PARAMS:
      return { ...state, schoolOrderParams: action.data }
    default:
      return state
  }
}

export default reducer
