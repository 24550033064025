export const SET_ADPUSH_LIST = 'SET_ADPUSH_LIST'
export type SET_ADPUSH_LIST = typeof SET_ADPUSH_LIST

export const ADD_ADPUSH = 'ADD_ADPUSH'
export type ADD_ADPUSH = typeof ADD_ADPUSH

export const UPDATE_ADPUSH = 'UPDATE_ADPUSH'
export type UPDATE_ADPUSH = typeof UPDATE_ADPUSH

export const DELETE_ADPUSH = 'DELETE_ADPUSH'
export type DELETE_ADPUSH = typeof DELETE_ADPUSH

export const SET_ADPUSH_COVER_UPTOKEN = 'SET_ADPUSH_COVER_UPTOKEN'
export type SET_ADPUSH_COVER_UPTOKEN = typeof SET_ADPUSH_COVER_UPTOKEN

export const SET_ADPUSH_COUPON_IDNAME = 'SET_ADPUSH_COUPON_IDNAME'
export type SET_ADPUSH_COUPON_IDNAME = typeof SET_ADPUSH_COUPON_IDNAME
