import React, { Component } from 'react'
import { ConnectedRouter } from 'connected-react-router'
// import { createHashHistory } from 'history'

// const history = createHashHistory()
import '../global.module.less'

import { Provider } from 'react-redux'
import { store, history } from 'src/redux/configureStore'

import Router from 'src/router/router'
import { message } from 'antd'

// const { store } = configureStore({}, history)

class App extends Component {
  componentDidMount() {
    message.config({ maxCount: 3 })
  }
  render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Router />
        </ConnectedRouter>
      </Provider>
    )
  }
}

export default App
