export const SET_COMPANY_LINES = 'SET_COMPANY_LINES'
export type SET_COMPANY_LINES = typeof SET_COMPANY_LINES

export const SET_SCHEDULING_LINES = 'GET_SCHEDULING_LINES'
export type GET_SCHEDULING_LINES = typeof SET_SCHEDULING_LINES

export const SET_SCHEDULING_FROM = 'SET_SCHEDULING_FROM'
export type SET_SCHEDULING_FROM = typeof SET_SCHEDULING_FROM

export const SET_CAR_LIST = 'SET_CAR_LIST'
export type SET_CAR_LIST = typeof SET_CAR_LIST

export const SET_STAFF_LIST = 'SET_STAFF_LIST'
export type SET_STAFF_LIST = typeof SET_STAFF_LIST

export const SET_LINE_ORDER_LIST = 'SET_LINE_ORDER_LSIT'
export type SET_LINE_ORDER_LSIT = typeof SET_LINE_ORDER_LIST

export const SET_SEATS_INFO_LIST = 'SET_SEATS_INFO_LIST'
export type SET_SEATS_INFO_LIST = typeof SET_SEATS_INFO_LIST

export const SET_FERRY_DRIVER_LIST = 'SET_FERRY_DRIVER_LIST'
export type SET_FERRY_DRIVER_LIST = typeof SET_FERRY_DRIVER_LIST

export const SET_STAFF_COMPANY_LIST = 'SET_STAFF_COMPANY_LIST'
export type SET_STAFF_COMPANY_LIST = typeof SET_STAFF_COMPANY_LIST

export const SET_ADD_SCHEDULING_STAFF_COMPANY =
  'SET_ADD_SCHEDULING_STAFF_COMPANY'
export type SET_ADD_SCHEDULING_STAFF_COMPANY = typeof SET_ADD_SCHEDULING_STAFF_COMPANY

export const SET_INFO_WITH_PRICE = 'SET_INFO_WITH_PRICE'
export type SET_INFO_WITH_PRICE = typeof SET_INFO_WITH_PRICE

export const SET_CHANGE_BATCHS = 'SET_CHANGE_BATCHS'
export type SET_CHANGE_BATCHS = typeof SET_CHANGE_BATCHS

export const SET_POINT_CONTENT = 'SET_POINT_CONTENT'
export type SET_POINT_CONTENT = typeof SET_POINT_CONTENT

export const SET_BUS_LINE_INFO = 'SET_BUS_LINE_INFO'
export type SET_BUS_LINE_INFO = typeof SET_BUS_LINE_INFO

export const SET_LINE_AREA = 'SET_LINE_AREA'
export type SET_LINE_AREA = typeof SET_LINE_AREA

export const SET_SYSTEM_AREA = 'SET_SYSTEM_AREA'
export type SET_SYSTEM_AREA = typeof SET_SYSTEM_AREA
