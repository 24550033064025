import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import userReducer from 'src/redux/user/reducer'
import systemReducer from 'src/redux/system/reducer'
import carReducer from 'src/redux/car/reducer'
import couponReducer from 'src/redux/coupon/reducer'
import staffReducer from 'src/redux/staff/reducer'
import lineReducer from 'src/redux/line/reducer'
import RegionReducer from 'src/redux/region/reducer'
import AdPushReducer from 'src/redux/adpush/reducer'
import commonReducer from 'src/redux/common/reducer'
import statisticalReducer from 'src/redux/statistical/reducer'
import carInspectionReducer from './car_inspection/reducer'
import noticeReducer from './notice/reducer'
import qrReducer from './qr/reducer'
import schoolReducer from './school/reducer'

import { History } from 'history'

export default (history: History) =>
  combineReducers({
    router: connectRouter(history),
    user: userReducer,
    system: systemReducer,
    car: carReducer,
    coupon: couponReducer,
    staff: staffReducer,
    line: lineReducer,
    region: RegionReducer,
    adpush: AdPushReducer,
    common: commonReducer,
    statistical: statisticalReducer,
    carInspection: carInspectionReducer,
    notice: noticeReducer,
    qr: qrReducer,
    school: schoolReducer
  })
