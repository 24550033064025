import * as constants from './constants'
import { AllCarInspectionActionTypes } from './actions'

import * as APIParams from 'src/types/car_inspection/request'
import * as APIRespones from 'src/types/car_inspection/respones'

import { cloneDeep } from 'lodash'
import {
  addItemObjectArray,
  updateObjectArrayItem,
  deleteItemInObjectArray
} from 'src/utils/tool'

export const initalSearchParams: APIParams.ReservationListParam = {
  username: '',
  phone: '',
  car_num: '',
  status: '',
  start_time: '',
  end_time: '',
  page_num: 1,
  page_size: 10,
  type: ''
}

export const initalFeeSearchParams: APIParams.FeeListParam = {
  company_id: '',
  car_type_id: '',
  fee_name: '',
  page_num: 1,
  page_size: 10
}

export interface CarInspectionState {
  reservationList: APIRespones.ReservationList
  reservationSearchParams: APIParams.ReservationListParam
  agentList: APIRespones.AgentList
  agentSearchParams: APIParams.AgentListParams
  agentOrderList: APIRespones.AgentOrderList
  agentListSlim: APIRespones.AgentBase[]
  timeRange: APIRespones.SettingItem[]
  settingList: APIRespones.SettingList // 预约工位列表
  stationListSlim: APIRespones.StationBase[]
  feeList: APIRespones.FeeList
  feeSearchParams: APIParams.FeeListParam
  carTypes: APIRespones.CarTypeItem[]
}

const INITAL_STATE: CarInspectionState = {
  reservationList: {
    rows: [],
    total: 0
  },
  reservationSearchParams: initalSearchParams,
  agentList: {
    rows: [],
    total: 0
  },
  agentSearchParams: {
    page_num: 1,
    page_size: 10
  },
  agentOrderList: {
    rows: [],
    total: 0,
    total_fee: 0
  },
  agentListSlim: [],
  timeRange: [],
  settingList: {
    rows: [],
    total: 0
  },
  stationListSlim: [],
  feeList: {
    rows: [],
    total: 0
  },
  feeSearchParams: initalFeeSearchParams,
  carTypes: []
}

const carInspectionReducer = (
  state: CarInspectionState = INITAL_STATE,
  action: AllCarInspectionActionTypes
): CarInspectionState => {
  let list: APIRespones.ReservationItem[] = []
  let agentList: APIRespones.AgentItem[] = []
  let settingList: APIRespones.SettingItem[] = []
  let feeList: APIRespones.FeeItem[] = []

  switch (action.type) {
    case constants.SET_RESERVATION_LIST:
      return {
        ...state,
        reservationList: action.data
      }
    case constants.SET_RESERVATION_SEARCH_PARAMS:
      return {
        ...state,
        reservationSearchParams: action.data
      }
    case constants.UPDATE_RESERVATION:
      // list = _updateReservationStatus(state.reservationList.rows, action.data)
      list = updateObjectArrayItem(action.data, state.reservationList.rows)

      return {
        ...state,
        reservationList: {
          rows: list,
          total: state.reservationList.total
        }
      }
    case constants.SET_AGENT_LIST:
      return {
        ...state,
        agentList: action.data
      }
    case constants.SET_AGENT_SEARCH_PARAMS:
      return {
        ...state,
        agentSearchParams: action.data
      }
    case constants.SET_AGENT_LIST_SLIM:
      return {
        ...state,
        agentListSlim: action.data
      }
    case constants.SET_AGENT_ORDER_LIST:
      return {
        ...state,
        agentOrderList: action.data
      }
    case constants.ADD_AGENT:
      agentList = _addAgent(action.data, state.agentList.rows)

      return {
        ...state,
        agentList: {
          rows: agentList,
          total: ++state.agentList.total
        }
      }
    case constants.UPDATE_AGENT:
      agentList = updateObjectArrayItem(action.data, state.agentList.rows)

      return {
        ...state,
        agentList: {
          rows: agentList,
          total: state.agentList.total
        }
      }
    case constants.SET_TIME_RANGE:
      return {
        ...state,
        timeRange: action.data
      }
    case constants.SET_SETTING_LIST:
      return {
        ...state,
        settingList: action.data
      }
    case constants.ADD_SETTING:
      settingList = addItemObjectArray(action.data, state.settingList.rows, [
        10,
        25,
        50
      ])

      return {
        ...state,
        settingList: {
          rows: settingList,
          total: ++state.settingList.total
        }
      }
    case constants.UPDATE_SETTING:
      settingList = updateObjectArrayItem(action.data, state.settingList.rows)

      return {
        ...state,
        settingList: {
          rows: settingList,
          total: state.settingList.total
        }
      }
    case constants.DELETE_SETTING:
      settingList = deleteItemInObjectArray(action.data, state.settingList.rows)

      return {
        ...state,
        settingList: {
          rows: settingList,
          total: --state.settingList.total
        }
      }
    case constants.SET_STATION_LIST_SLIM:
      return {
        ...state,
        stationListSlim: action.data
      }
    case constants.SET_FEE_LIST:
      return {
        ...state,
        feeList: action.data
      }
    case constants.SET_FEE_SEARCH_PARAMS:
      return {
        ...state,
        feeSearchParams: action.data
      }
    case constants.ADD_FEE:
      feeList = addItemObjectArray(action.data, state.feeList.rows, [
        10,
        25,
        50
      ])

      return {
        ...state,
        feeList: {
          rows: feeList,
          total: ++state.feeList.total
        }
      }
    case constants.UPDATE_FEE:
      feeList = updateObjectArrayItem(action.data, state.feeList.rows)

      return {
        ...state,
        feeList: {
          rows: feeList,
          total: state.feeList.total
        }
      }
    case constants.DELETE_FEE:
      feeList = deleteItemInObjectArray(action.data, state.feeList.rows)

      return {
        ...state,
        feeList: {
          rows: feeList,
          total: --state.feeList.total
        }
      }
    case constants.SET_CAR_TYPES:
      return {
        ...state,
        carTypes: action.data
      }
    default:
      return state
  }
}

const _updateReservationStatus = (
  list: APIRespones.ReservationItem[],
  data: APIParams.UpdateStatusParams
) => {
  let nlist = cloneDeep(list)

  nlist.every(item => {
    if (item.id === data.id) {
      Object.assign(item, data)
      return false
    } else {
      return true
    }
  })

  return nlist
}

const _addAgent = (
  data: APIRespones.AgentItem,
  oList: APIRespones.AgentItem[]
): APIRespones.AgentItem[] => {
  let list = cloneDeep(oList)

  // 如果当前页数据已满，防止自动换页，先删去除末尾项
  if (list.length === 10 || list.length === 25 || list.length === 50) {
    list.pop()
  }

  list.unshift(data)

  return list
}

export default carInspectionReducer
