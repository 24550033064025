import * as constants from './constants'
import { AllStaffActionTypes } from './actions'
import * as APIRepones from 'src/types/staff/respones'

export interface StaffState {
  companys: APIRepones.CompanysList
  drivers: APIRepones.DriverItem[]
}

const INITAL_STATE: StaffState = {
  companys: [],
  drivers: []
}

function staffReducer(state = INITAL_STATE, action: AllStaffActionTypes) {
  switch (action.type) {
    case constants.SET_COMPANYS_LIST:
      return {
        ...state,
        companys: action.data
      }
    case constants.SET_DRIVERS_LIST:
      return {
        ...state,
        drivers: action.data
      }
    default:
      return state
  }
}

export default staffReducer
