/*
 * LoginReducer
 *
 * The reducer takes care of our data. Using actions, we can change our
 * application state.
 * To add a new action, add it to the switch statement in the reducer function
 *
 * Example:
 * case YOUR_ACTION_CONSTANT:
 *   return state.set('yourStateVariable', true);
 */
import { message } from 'antd'
import {
  COMMON_LOADING,
  COMMON_SUCCESS,
  COMMON_ERROR,
  COMMON_API_SET
} from './constants'
import { AllCommonActions } from './actions'
import { APIClass } from 'src/types/redux/index'

// The initial state of the login
const initialState = {
  isLoading: false
}

export type CommonState = Readonly<typeof initialState>

function commonReducer(state = initialState, action: AllCommonActions) {
  switch (action.type) {
    case COMMON_LOADING:
      return Object.assign({}, state, { isLoading: action.payload })
    case COMMON_SUCCESS:
      message.success(action.payload.message)
      return Object.assign({}, state, {})
    case COMMON_ERROR:
      message.error(action.payload.message)
      return Object.assign({}, state, {})
    case COMMON_API_SET:
      const newState: any = {}
      newState[action.payload.className.name] = action.payload.data
      return Object.assign({}, state, newState)
    default:
      return state
  }
}

export function getData<T>(className: APIClass, state: any): T {
  return state[className.name]
}

export default commonReducer
