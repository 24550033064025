export const SET_SPECAIL_DRIVER = 'SET_SPECAIL_DRIVER'
export type SET_SPECAIL_DRIVER = typeof SET_SPECAIL_DRIVER

export const SET_SPECAIL_DRIVER_PAGE = 'SET_SPECAIL_DRIVER_PAGE'
export type SET_SPECAIL_DRIVER_PAGE = typeof SET_SPECAIL_DRIVER_PAGE

export const SET_STAFF_SPECAIL_INFO = 'SET_STAFF_SPECAIL_INFO'
export type SET_STAFF_SPECAIL_INFO = typeof SET_STAFF_SPECAIL_INFO

export const SET_INTER_CITY_SPECAIL_DATA = 'SET_INTER_CITY_SPECAIL_DATA'
export type SET_INTER_CITY_SPECAIL_DATA = typeof SET_INTER_CITY_SPECAIL_DATA

export const SET_INTER_CITY_PARAMS = 'SET_INTER_CITY_PARAMS'
export type SET_INTER_CITY_PARAMS = typeof SET_INTER_CITY_PARAMS
