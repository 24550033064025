/**
 * 定义应用路由
 */
import * as React from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
// import Dashboard from 'src/layouts/dashboard/Dashboard'
// import FullScreen from 'src/layouts/fullscreen/FullScreen'
import { Spin } from 'antd'

const Dashboard = React.lazy(() => import('src/layouts/dashboard/Dashboard'))
const FullScreen = React.lazy(() => import('src/layouts/fullscreen/FullScreen'))

// 按照 Layout 分组路由
const Router = () => {
  return (
    <HashRouter>
      <React.Suspense fallback={<Spin tip="Loading..." />}>
        <Switch>
          <Route path="/user" component={FullScreen} />
          <Route path="/" component={Dashboard} />
        </Switch>
      </React.Suspense>
    </HashRouter>
  )
}

export default Router
