import * as constants from './constants'
import { AllRegionActionTypes } from './actions'
import * as APIRepones from 'src/types/region/respones'
import { BasePagination } from 'src/types'

export interface RegionState {
  list: APIRepones.RegionItem[]
  carCategory: APIRepones.CarCate[]
  areaModelList: APIRepones.AreaModelList
  taxiAreaModelParams: BasePagination
}

const INITAL_STATE: RegionState = {
  list: [],
  carCategory: [],
  areaModelList: { total: 0, rows: [] },
  taxiAreaModelParams: { page_size: 10, page_num: 1 }
}

function regionReducer(state = INITAL_STATE, action: AllRegionActionTypes) {
  switch (action.type) {
    case constants.SET_REGION_LIST:
      return {
        ...state,
        list: action.data
      }
    case constants.SET_TAXI_AREA_MODEL_LIST:
      return { ...state, areaModelList: action.data }
    case constants.SET_TAXI_AREA_MODEL_PARAMS:
      return { ...state, taxiAreaModelParams: action.data }
    case constants.SET_CAR_CATE:
      return { ...state, carCategory: action.data }
    default:
      return state
  }
}

export default regionReducer
