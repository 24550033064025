/*
 * LoginReducer
 *
 * The reducer takes care of our data. Using actions, we can change our
 * application state.
 * To add a new action, add it to the switch statement in the reducer function
 *
 * Example:
 * case YOUR_ACTION_CONSTANT:
 *   return state.set('yourStateVariable', true);
 */
import {
  USER_LOGIN_SUCCESS,
  USER_ENV_CHANGE,
  USER_CLICK_COUNT_CHANGE
} from './constants'
import { UserLoginSuccessAction, AllUserAction } from './actions'
import { ROLE, Environment } from 'src/utils/authority'

// The initial state of the login
const initialState = {
  role: ROLE.NONE,
  envChangeCount: 0,
  env: Environment.none
}

export type UserState = Readonly<typeof initialState>

function userReducer(state = initialState, action: AllUserAction) {
  switch (action.type) {
    case USER_LOGIN_SUCCESS:
      return Object.assign({}, state, {
        role: action.role
      })
    case USER_ENV_CHANGE:
      return {
        ...state,
        env: action.data
      }
    case USER_CLICK_COUNT_CHANGE:
      return {
        ...state,
        envChangeCount: action.data
      }

    default:
      return state
  }
}

export default userReducer
