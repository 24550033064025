import IStorage from '../IStorage'
import Cookies from 'js-cookie'

const storakie: IStorage = {
  get(key: string) {
    return localStorage.getItem(key)
  },
  set(key: string, data: any, option: Cookies.CookieAttributes = {}) {
    // const option: Cookies.CookieAttributes = { secure: true }
    // const option: Cookies.CookieAttributes = {}
    localStorage.setItem(key, data)
    Cookies.set(key, data, option)
  }
}

export const KEYS = {
  TOKEN: 'Token',
  ENVIRONMENT: 'env'
}

export default storakie
